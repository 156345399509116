// @flow
import * as React from "react";
import Dialog from "react-toolbox/lib/dialog/Dialog";

export type ToolboxDialogProps = {
  active: boolean,
  title: string,
  bodyText: string | React.Node
};

type Props = {|
  handleToggle?: () => void,
  modalProps: ToolboxDialogProps,
  id?: string
|};

function ADialog({ handleToggle, modalProps, id }: Props): React.Node {
  return (
    <div>
      <Dialog
        id={id}
        active={modalProps.active}
        onEscKeyDown={() => handleToggle?.()}
        onOverlayClick={() => {}}
        title={modalProps.title}
        className="dialogMainWrapper"
      >
        {modalProps.bodyText}
      </Dialog>
    </div>
  );
}

export default ADialog;

import * as React from "react";
import { render } from "react-dom";
import AppContainer from "./containers/AppContainer";
import { AppProviders } from "./containers/AppProviders";
import * as serviceWorker from "./serviceWorker";
import eventLogger from "./utils/eventLogger";

import "./index.scss";

function App() {
  React.useEffect(() => {
    eventLogger.infoWithMetadata("App is initialized", JSON.stringify({}));
  }, []);

  return (
    <AppProviders>
      <AppContainer />
    </AppProviders>
  );
}

render(
  <App />,
  // eslint-disable-next-line no-restricted-properties
  document.getElementById("app")
);

serviceWorker.unregister();

// @flow
import * as React from "react";
import Divider from "@material-ui/core/Divider";
import OButton from "../core-libs/components/Button";
import ADialog from "../core-libs/components/Dialog";
const closeIcon = require("../assets/images/close-x.svg");

type Props = {|
  isItemScanningRequired?: boolean,
  onResetItemScanningRequired?: (value: boolean) => void
|};

export default class ItemScanningRequired extends React.Component<Props> {
  onResetItemScanningRequired(value: boolean) {
    if (this.props.onResetItemScanningRequired) {
      this.props.onResetItemScanningRequired(value);
    }
  }
  constructedProps(): { active: boolean, title: string, bodyText: React.Node } {
    const { isItemScanningRequired = false } = this.props;
    return {
      active: isItemScanningRequired,
      title: "",
      bodyText: (
        <div id="itemScanningRequiredDialog">
          <div className="itemScanTitle">
            <div className="itemScanText">Item Scanning Required</div>
            <div className="closeBtn">
              <span
                data-testid="item-scanning-close-icon"
                className="closeImg"
                onClick={this.onResetItemScanningRequired.bind(this, false)}
              >
                <img alt="closeIcon" src={closeIcon} />
              </span>
            </div>
          </div>
          <div className="itemScanningBodyText">This pick tour requires item validation.</div>
          <Divider />
          <div className="itemScanActionsWrapper">
            <OButton
              onButtonClick={this.onResetItemScanningRequired.bind(this, true)}
              id="proceedBtn"
              text="Proceed"
              customClass="proceedButtonItemScanModal"
              data-testid="item-scanning-proceed"
            />
            <OButton
              onButtonClick={this.onResetItemScanningRequired.bind(this, false)}
              id="cancelBtn"
              text="Cancel"
              customClass="cancelButtonItemScanModal"
              data-testid="item-scanning-cancel"
            />
          </div>
        </div>
      )
    };
  }
  render(): React.Node {
    return <ADialog modalProps={this.constructedProps()} />;
  }
}

/**
 * Copyright 2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 *
 * @flow
 */
import * as React from "react";
import AZOauth from "@az/oauth";
import { env } from "../utils/env";

const config = {
  clientId: env.COMMERCIAL_OAUTH_CLIENT_ID,
  redirectUrl: env.HOST_URL,
  scopes: [env.OAUTH_SCOPE],
  serviceConfiguration: {
    authorizationEndpoint: env.GATEWAY_BASE_URL + "authorize",
    tokenEndpoint: env.GATEWAY_BASE_URL + "token",
    revocationEndpoint: env.GATEWAY_BASE_URL + "token/revoke"
  }
};

const auth: {
  authorize: () => Promise<{|
    accessToken: string,
    refreshToken: string
  |} | void>,
  revoke: () => Promise<void>,
  refresh: () => Promise<{|
    accessToken: string,
    refreshToken: string
  |}>,
  getTokens: () => Promise<{| accessToken: string, refreshToken: string |} | void>
} = AZOauth(config, { storage: global.sessionStorage });

const AuthContext = React.createContext<$Call<typeof AZOauth, typeof config>>(auth);

type Props = {| children: React.Node |};

function AuthProvider(props: Props): React.Node {
  return <AuthContext.Provider value={auth} {...props} />;
}

function useAuth(): typeof auth {
  return React.useContext(AuthContext);
}

export { AuthProvider, useAuth, auth };

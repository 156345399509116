import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import OButton from "../core-libs/components/Button";
import eventLogger from "../utils/eventLogger";
import getCurrentScreen from "../utils/getCurrentScreen";

export default function ErrorFallback(props) {
  const { error, resetErrorBoundary } = props;

  const currentStep = useSelector((state) => state.inventory.currentStep);

  useEffect(() => {
    const currentScreen = getCurrentScreen(currentStep);
    // Use only top-most call-sites from the stack
    const stack = error.stack.split("\n").slice(0, 3).join("\n");
    eventLogger.errorWithMetadata(
      error.message,
      JSON.stringify({ currentScreen, stack }),
      "crash",
      { immediate: true }
    );
  }, [error.message, error.stack, currentStep]);

  function handleReset() {
    const currentScreen = getCurrentScreen(currentStep);
    eventLogger.infoWithMetadata("ErrorFallback Retry Clicked", JSON.stringify({ currentScreen }), {
      immediate: true
    });
    resetErrorBoundary();
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        height: "100%",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <p style={{ fontSize: 18 }}>Something is stuck in the gears. Please try again.</p>
      <OButton text="Try Again" type="primary" size="lg" onButtonClick={handleReset} />
    </div>
  );
}

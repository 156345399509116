// @flow
import * as React from "react";
import alertIcon from "../../assets/images/Error.svg";
import eventLogger from "../../utils/eventLogger";

export type ErrorType =
  | boolean
  | {|
      code: string,
      detail: string, // usually empty
      id: string,
      links: Array<any>,
      status: string,
      title: string
    |}
  | {|
      message: string,
      code?: string
      // ...
    |};

type Props = {|
  type: string,
  error?: ErrorType
|};

const ErrorMessage = ({ type, error }: Props): React.Node => {
  const errorMessage = getErrorMessage(error);

  React.useEffect(() => {
    eventLogger.errorWithMetadata(
      `ErrorMessage component displayed`,
      JSON.stringify({ type, error })
    );
  }, [type, error]);

  return (
    <div id="errorMessageWrapper">
      <div className="alertIcon">
        <img alt="alertIcon" src={alertIcon} />
      </div>
      <div id="errorMessage">
        Error {type}. {errorMessage ? `${errorMessage}. ` : ""}Please try again.
      </div>
    </div>
  );
};

function getErrorMessage(error) {
  if (typeof error === "boolean") {
    return undefined;
  }
  // Internal erver errors are not usually helpful to end users, so we replace
  // those with generic message
  if (error?.code && error.code >= "500") {
    return "Internal server error";
  }
  if (error?.message) {
    return error.message;
  }
  if (error?.title) {
    return error.title;
  }
  if (error?.detail) {
    return error.detail;
  }
  return undefined;
}

export default (React.memo<Props>(ErrorMessage): React.AbstractComponent<Props>);

// @flow
import * as types from "../constants/actionTypes";

type State = {
  isManualStore: boolean,
  isFetchingTokenInfo: boolean,
  userDetails: void | {
    AuthType: string,
    claims: {
      username: string,
      client_id: string,
      uid?: string,
      cn?: string
    },
    ClientID: string,
    expires_at: string
  }
};

const initialState: State = {
  isManualStore: false,
  isFetchingTokenInfo: false,
  userDetails: undefined
};

export const userReducer = (state: State = initialState, action: any): State => {
  switch (action.type) {
    case types.SET_USER_DETAILS: {
      return { ...state, userDetails: action.payload };
    }
    case types.REMOVE_STORE_DATA: {
      return initialState;
    }
    case types.MANUAL_STORE_UPDATE: {
      return {
        ...state,
        isManualStore: action.payload
      };
    }
    case types.IS_FETCHING_TOKENINFO:
      return { ...state, isFetchingTokenInfo: action.payload };
    default:
      return state;
  }
};

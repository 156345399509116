// @flow
import * as React from "react";
import { Provider } from "react-redux";
import ThemeProvider from "react-toolbox/lib/ThemeProvider";
import { ErrorBoundary } from "react-error-boundary";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "../store";
import { AuthProvider } from "../components/AuthContext";
/* react-toolbox create-react-app */
import theme from "../assets/react-toolbox/theme";
import ErrorFallback from "../containers/ErrorFallback";

type Props = {| children: React.Node |};

export function AppProviders({ children }: Props): React.Node {
  return (
    <Provider store={store}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <AuthProvider>{children}</AuthProvider>
          </ThemeProvider>
        </PersistGate>
      </ErrorBoundary>
    </Provider>
  );
}

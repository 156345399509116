import * as React from "react";
import { useSelector } from "react-redux";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import { size } from "lodash";
import Button from "../core-libs/components/Button";
import Dialog from "../core-libs/components/Dialog";
import ErrorMessage from "../core-libs/components/ErrorMessage";
import { getMetadata, getStores } from "../actions/loginActions";
import closeIcon from "../assets/images/close-x.svg";
import locationIcon from "../assets/images/location.svg";

const REG_EX_IGNITION = /^\d*$/;
const deviceInfo = navigator.userAgent;
const isTC75 = deviceInfo.includes("TC75");

export default function ChooseStoreModal({ active, onClose, onSubmit }) {
  const uid = useSelector((s) => s.user.userDetails?.claims.uid);
  const [storeId, setStoreId] = React.useState("");
  const [stage, setStage] = React.useState("searchOrLocate"); // "searchOrLocate" | "confirmStore"

  const handleSearchOrLocateSubmit = React.useCallback(() => {
    setStage("confirmStore");
  }, []);

  const handleSubmit = async (value) => {
    await onSubmit(value);
    // Clenup local state manually once the submit handler returns, because the
    // modal is not unmounted
    setStoreId("");
    setStage("searchOrLocate");
  };

  return (
    <Dialog
      modalProps={{
        active,
        title: "",
        bodyText: (
          <div>
            <div className="ChooseStoreModal-title">
              <div className="ChooseStoreModal-title-text">Choose a Store</div>
              <div className="ChooseStoreModal-close-icon">
                <img alt="close" src={closeIcon} onClick={onClose} />
              </div>
            </div>
            {stage === "searchOrLocate" ? (
              <SearchOrLocate
                storeId={storeId}
                uid={uid}
                onStoreIdChange={setStoreId}
                onSubmit={handleSearchOrLocateSubmit}
              />
            ) : stage === "confirmStore" ? (
              <ConfirmStore
                storeId={storeId}
                onConfirm={handleSubmit}
                onDecline={() => {
                  setStage("searchOrLocate");
                }}
              />
            ) : null}
          </div>
        )
      }}
      handleToggle={onClose}
    />
  );
}

function SearchOrLocate({ storeId, uid, onStoreIdChange, onSubmit }) {
  const [error, setError] = React.useState();

  React.useEffect(() => {
    (async () => {
      if (uid && !storeId) {
        try {
          const metadata = await getMetadata(uid);
          // Automatically populate storeId and move to confirmation screen when
          // the user locationCode is STORES.
          if (metadata.locationCode === "STORES" && metadata.storeID && !storeId) {
            onStoreIdChange(metadata.storeID);
            onSubmit();
          }
        } catch {
          // bail if this request fails
        }
      }
    })();
  }, [onStoreIdChange, onSubmit, storeId, uid]);

  return (
    <>
      <div className="ChooseStoreModal-body">
        <div>Search or locate a store to see picking orders.</div>
      </div>
      <TextField
        autoFocus
        className="ChooseStoreModal-input"
        id="storeValue"
        label="Store #"
        type="number"
        autoComplete="off"
        margin="normal"
        variant="outlined"
        value={storeId}
        onChange={(e) =>
          size(e.target.value) <= 8 &&
          REG_EX_IGNITION.test(e.target.value) &&
          onStoreIdChange(e.target.value)
        }
        onKeyUp={(e) => {
          if (e.keyCode === 13 || (e.keyCode === 9 && isTC75)) {
            onSubmit();
          }
        }}
      />
      {error ? (
        <div className="ChooseStoreModal-error">
          <ErrorMessage type={error} />
        </div>
      ) : null}
      <div className="ChooseStoreModal-action-buttons">
        <GeolocationButton
          onStoreIdChange={onStoreIdChange}
          onSubmit={() => {
            setError();
            onSubmit();
          }}
          onFailure={setError}
        />
        <div className="ChooseStoreModal-action-buttons-divider"></div>
        <Button
          id="submitButton"
          text={"Submit"}
          customClass="ChooseStoreModal-action-button"
          disabled={storeId === ""}
          onButtonClick={onSubmit}
        />
      </div>
    </>
  );
}

function ConfirmStore({ storeId, onDecline, onConfirm }) {
  return (
    <>
      <div className="ChooseStoreModal-body">
        <p>You have entered:</p>
        <span className="ChooseStoreModal-selected-store">Store: #{storeId.padStart(4, "0")}</span>
        <p>Is this the correct store?</p>
      </div>
      <Divider />
      <div className="ChooseStoreModal-action-buttons">
        <Button
          text="No"
          customClass="ChooseStoreModal-action-button ChooseStoreModal-action-button--secondary"
          onButtonClick={onDecline}
        />
        <div className="ChooseStoreModal-action-buttons-divider"></div>
        <Button
          text="Yes"
          customClass="ChooseStoreModal-action-button"
          // TODO: not sure if we need to pad with 0s, investigate
          onButtonClick={() => onConfirm(storeId.padStart(8, "0"))}
        />
      </div>
    </>
  );
}

function GeolocationButton({ onStoreIdChange, onSubmit, onFailure }) {
  const [geolocationInProgress, setGeolocationInProgress] = React.useState(false);

  const handleGeolocationClick = () => {
    setGeolocationInProgress(true);
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        setGeolocationInProgress(false);

        try {
          const data = await getStores({
            lattitude: position.coords.latitude,
            longitude: position.coords.longitude,
            maxMilesRange: "10",
            getStoresByAddress: false,
            address: ""
          });

          if (data) {
            onStoreIdChange(Number(data.stores[0].storeId).toString());
            onSubmit();
          } else {
            onFailure("No store found nearby.");
          }
        } catch (error) {
          onFailure("Failed to geolocate store.");
        }
        setGeolocationInProgress(false);
      },
      (error) => {
        onFailure(error.message);
        setGeolocationInProgress(false);
      },
      { enableHighAccuracy: true, timeout: 10000, maximumAge: 0 }
    );
  };
  return (
    <Button
      loading={geolocationInProgress}
      text={
        <span>
          <img src={locationIcon} />
          Locate me
        </span>
      }
      customClass="ChooseStoreModal-action-button ChooseStoreModal-action-button--secondary"
      onButtonClick={handleGeolocationClick}
    />
  );
}

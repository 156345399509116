// @flow
import { env } from "../utils/env";

export const GET_ORDERS = `${env.BASE_URL}/commercial/shop/v1/dg/`;
export const POST_PICK_TOUR = `${env.BASE_URL}/commercial/shop/v1/pick/create`;
export const POST_UPDATE_PICK_TOUR = `${env.BASE_URL}/commercial/shop/v1/pick/item/update`;
export const GET_STORES = `${env.BASE_URL}/enterprise/store/v1/proximity-request`;
export const POST_TIMEOUT = `${env.BASE_URL}/commercial/shop/v1/pick/timeout`;
export const POST_UPDATE_PICK_TOUR_ALL = `${env.BASE_URL}/commercial/shop/v1/pick/update`;
export const PRINT_PICKTOUR = `${env.BASE_URL}/commercial/shop/v1/pick/print`;
export const SKIP_URL_STORE_ID = `${env.BASE_URL}/commercial/shop/v1/pick/skip`;
export const FORWARD_URL = `${env.BASE_URL}/commercial/shop/v1/dg/forward`;
export const CONTINUE_STORE_URL = `${env.BASE_URL}/commercial/shop/v1/dg/update`;
export const RETRY_URL = `${env.BASE_URL}/commercial/shop/v1/dg/retry`;
export const GET_EMPLOYEE_DATA_PREFIX = `${env.BASE_URL}/commercial/shop/v1/user`;
export const GET_EMPLOYEE_DATA_SUFFIX = `/metadata`;
export const POST_DRIVER_LOGOFF = `${env.BASE_URL.replace(
  "/internal",
  ""
)}/external/integrations/dispatching-routing/v1/driver-logoff`;

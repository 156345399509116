export const HEADERS = {
  ACTIVE_PICKTOUR: "Active Pick Tour",
  SKIP_ITEM_VALIDATION: "Skipped Item Validation",
  PENDING_ORDERS: "Pending Orders",
  COMPLETED_PICKTOUR: "Pick Completed"
};

export default function getCurrentScreen(currentStep) {
  if (currentStep === 2) {
    return HEADERS.ACTIVE_PICKTOUR;
  } else if (currentStep === 0) {
    return "";
  } else if (currentStep === 3) {
    return HEADERS.SKIP_ITEM_VALIDATION;
  } else if (currentStep === 4) {
    return HEADERS.COMPLETED_PICKTOUR;
  } else {
    return HEADERS.PENDING_ORDERS;
  }
}

/**
 * Copyright 2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 * @flow
 */
import axios from "axios";
import { store } from "../store";
import { clearSession } from "../constants/Authorization-util";
import { auth } from "../components/AuthContext";
import { env } from "./env";

const instance: any = axios.create({
  headers: {
    "content-type": "application/json"
  }
});

async function tokenInterceptor(config) {
  const tokens = await auth.getTokens();

  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: tokens && `Bearer ${tokens.accessToken}`
    }
  };
}

// Note: we count retries _globally_ for all requests, not per-request.
const MAX_RETRIES = 5;
let retries = 0;

async function handleUnauthorized(error) {
  const { config, response } = error;

  const configURLCheck = "/auth/oauth/v2/token";
  if (response && response.status === 400 && config.url === configURLCheck) {
    store.dispatch(clearSession("signout"));

    return Promise.reject(error);
  }

  if (response && response.status === 401) {
    if (retries >= MAX_RETRIES) {
      retries = 0;

      store.dispatch(clearSession("signout"));

      return Promise.reject(error);
    }

    retries += 1;
    await auth.refresh();
    return instance(config);
  }

  retries = 0;
  // Logging error in dev so it's harder to miss
  // eslint-disable-next-line no-console
  console.warn(error, { response });

  return Promise.reject(error);
}

instance.interceptors.request.use(tokenInterceptor);
instance.interceptors.response.use(undefined, handleUnauthorized);

export default instance;

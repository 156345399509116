export default function getDevice() {
  const deviceInfo = navigator.userAgent;

  var device = "computer";
  if (deviceInfo.includes("TC75")) {
    device = "TC75";
  } else if (deviceInfo.includes("CT40")) {
    device = "CT40";
  }
  return device;
}

import { utcFormat } from "../utils/azday";
import * as types from "../constants/actionTypes";
import {
  GET_STORES,
  GET_EMPLOYEE_DATA_PREFIX,
  GET_EMPLOYEE_DATA_SUFFIX,
  POST_DRIVER_LOGOFF
} from "../constants/URL";
import fetcher from "../utils/fetcher";
import { env } from "../utils/env";
import eventLogger from "../utils/eventLogger";

async function getTokenInfo() {
  const { data } = await fetcher.get(`${env.GATEWAY_BASE_URL}tokeninfo`, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" }
  });

  return {
    ...data,
    ordersDownloadedTime: utcFormat() // TODO: move somewhere else
  };
}

export async function getStores(payload) {
  const { data } = await fetcher.post(GET_STORES, payload);
  return data;
}

export async function getMetadata(uid) {
  const { data } = await fetcher.get(
    `${GET_EMPLOYEE_DATA_PREFIX}/${uid}${GET_EMPLOYEE_DATA_SUFFIX}`,
    {
      headers: { "Content-Type": "application/x-www-form-urlencoded" }
    }
  );
  return data;
}

export function updateUserDetails() {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: types.IS_FETCHING_TOKENINFO, payload: true });
      const userData = getState().user.userDetails || (await getTokenInfo());
      if (!userData?.claims.uid) {
        userData.claims.uid = userData.claims.username;
      }
      dispatch({ type: types.IS_FETCHING_TOKENINFO, payload: false });
      dispatch({ type: types.SET_USER_DETAILS, payload: userData });
      // We want to trigger the ManualStoreNumber modal when the store is not
      // yet set
      if (!getState().store.storeId) {
        dispatch({ type: types.MANUAL_STORE_UPDATE, payload: true });
      }
    } finally {
      dispatch({ type: types.IS_FETCHING_TOKENINFO, payload: false });
    }
  };
}

export const updateManualStore = (value) => {
  return (dispatch) => {
    return dispatch({ type: types.MANUAL_STORE_UPDATE, payload: value });
  };
};

export const updateManualStoreWithSuccess = (value, manualStoreNumber) => {
  return (dispatch) => {
    dispatch({ type: types.MANUAL_STORE_UPDATE, payload: value });
    return dispatch({ type: types.SET_STORE_ID, payload: manualStoreNumber });
  };
};

export const postDriverLogoff = async (employeeID: string) => {
  let config = { headers: { "Content-Type": "application/json" } };

  try {
    await fetcher.post(POST_DRIVER_LOGOFF, { external_id: employeeID }, config);
  } catch (err) {
    //TODO - Is it possible to get the response message in here?
    eventLogger.errorWithMetadata(
      "Bringg Driver Logoff Error",
      JSON.stringify({ error: err.message }),
      "API Failed",
      { immediate: true }
    );
  }
};

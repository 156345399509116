// @flow
import * as React from "react";
import OButton from "../core-libs/components/Button";
import ErrorMessage, { type ErrorType } from "../core-libs/components/ErrorMessage";

type Props = {|
  errors?: {
    printError: ErrorType,
    getOrdersError: ErrorType
  },
  isSat2Sat?: boolean,
  handleCompleted: () => void
|};

type State = { loading: boolean };

class CompletedPick extends React.Component<Props, State> {
  state: State = {
    loading: false
  };

  onHandleCompleted: () => Promise<void> = async () => {
    try {
      this.setState({
        loading: true
      });
      await this.props.handleCompleted();
    } finally {
      this.setState({ loading: false });
    }
  };

  componentDidMount() {
    localStorage.setItem("isPickTourStarted", "false");
  }

  render(): React.Node {
    let { errors, isSat2Sat } = this.props;
    return (
      <div id="completePick">
        <div className="wrapper">
          {isSat2Sat ? (
            <>
              <span className="invoicesPrinted">Sat to Sat Transfer Label(s) Printed.</span>
              <span className="invoicesPrintedNoPadding">Please stage for hub driver pickup.</span>
            </>
          ) : (
            <span className="invoicesPrinted">
              Orders Being Finalized. Check Printer For Invoices
            </span>
          )}
          {errors?.printError ? (
            <div id="errorWrapperCompletedPick">
              <ErrorMessage type="printing" error={errors.printError} />
            </div>
          ) : null}
          {errors?.getOrdersError ? (
            <div id="errorWrapperCompletedPick">
              <ErrorMessage type="submitting" error={errors.getOrdersError} />
            </div>
          ) : null}
          <div>
            <span className="confirm">
              <OButton
                onButtonClick={this.onHandleCompleted}
                type="primary"
                id="btnCompleteItem"
                data-testid="active-view-complete-item"
                text="RETURN TO PENDING ORDERS"
                size="sm"
                customClass="confirmOk"
                loading={this.state.loading}
              />
            </span>
          </div>
        </div>
      </div>
    );
  }
}
export default CompletedPick;

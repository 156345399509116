import * as types from "../constants/actionTypes";

const initialState = { storeId: "" };

export const storeIdReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_STORE_ID:
      return { ...state, storeId: action.payload };
    default:
      return state;
  }
};

import React, { Component, Fragment } from "react";
// TODO: remove prop-types
import PropTypes from "prop-types"; // eslint-disable-line import/no-extraneous-dependencies
/* custom components */
/* Material UI */
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import OButton from "../components/Button";
import ADialog from "../components/Dialog";
import { version } from "../../../package.json";
import { formatTime } from "../../utils/azday";
import { ContentLoader, Rect } from "../../components/ContentLoader";
import eventLogger from "../../utils/eventLogger";
import getCurrentScreen from "../../utils/getCurrentScreen";

const closeIcon = require("../../assets/images/close-icon.svg");
const closeXIcon = require("../../assets/images/close-x.svg");
/* Local Styles */
const styles = {
  grow: {
    flexGrow: 1
  },
  list: {
    width: 300
  },
  fullList: {
    width: "auto"
  },
  refreshButtonContainer: {
    marginLeft: 10
  }
};

class ApplicationBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawer: false,
      // active: false,
      enableCancelTourPick: true
    };
    this.forceReloadApp = this.forceReloadApp.bind(this);
  }

  /* Based on the data create the DOM for active pick tour */
  constructedProps() {
    return {
      active: this.props.isSavePickTourActive, // this.state.active,
      title: "",
      bodyText: (
        <Fragment>
          <div className="skipTitle">
            <div className="skipText confirmationBoxHeader">Confirmation</div>
            <div className="closeBtn confirmationBoxHeader">
              <span className="closeImg" onClick={this.onNoButtonClick.bind(this)}>
                <img src={closeXIcon} alt="CloseX" />
              </span>
            </div>
          </div>
          <div className="skipBody f-12 p-16 confirmationBoxText">
            Do you want to save the active pick tour?
          </div>
          <Divider />
          <div className="skipAction">
            <OButton
              customClass="yesButton"
              id="submit"
              type="primary"
              text="YES"
              onButtonClick={this.onYesButtonClick.bind(this)}
              data-testid="save-active-pick-tour-yes"
            />
            <OButton
              id="skipAll"
              customClass="noSecondaryButton"
              text="NO"
              onButtonClick={this.onNoButtonClick.bind(this)}
              data-testid="save-active-pick-tour-no"
            />
          </div>
        </Fragment>
      )
    };
  }
  /* Open/Close the Side Menu */
  toggleDrawer(flag, e) {
    if (localStorage.getItem("isPickTourStarted") === "true") {
      this.setState({ enableCancelTourPick: true });
    } else {
      this.setState({ enableCancelTourPick: false });
    }
    this.setState({
      drawer: flag
    });
  }
  onLogout() {
    // call parent action for logout and clear the storage
    if (this.props.onLogout) {
      this.props.onLogout();
    }
  }

  onNoButtonClick() {
    this.props.upDateIsSavePicktourFlag(false);
  }

  handleToggle() {
    /* handle for side bar toggle */
    this.toggleDrawer();
    this.props.upDateIsSavePicktourFlag(true);
  }

  onYesButtonClick() {
    if (this.props.savePickTour(false, "CANCEL")) {
      this.props.upDateIsSavePicktourFlag(false);

      this.props.getOrders(this.props.store.storeId);
      localStorage.setItem("isPickTourStarted", false);
    }
  }

  showModalManualStore() {
    if (this.props.showModalManualStore) {
      this.props.showModalManualStore();
    }
  }

  async forceReloadApp() {
    const { currentStep } = this.props;
    const currentScreen = getCurrentScreen(currentStep);
    try {
      await eventLogger.infoWithMetadata(
        "Force Reload App",
        JSON.stringify({ screen: currentScreen }),
        {
          immediate: true
        }
      );
    } finally {
      window.location.reload();
    }
  }

  render() {
    const {
      id,
      title,
      data,
      classes,
      store,
      currentStep,
      isSavePickTourActive,
      isFetchingTokenInfo
    } = this.props;
    const sideList = (
      <div className={classes.list}>
        <List>
          <ListItem className="mainMenuListItem" button>
            <div id="closeBtn">
              <img src={closeIcon} alt="CloseX" onClick={this.toggleDrawer.bind(this, false)} />
            </div>
            <div className="menuWrapper">
              <div className="loginName">
                {data && data.claims && (
                  <span>
                    <span>{data.claims.cn}</span>
                  </span>
                )}
              </div>
              {data && data.ordersDownloadedTime && (
                <div className="loginTime">
                  Orders Downloaded: <span> {formatTime(data.ordersDownloadedTime)} </span>
                </div>
              )}
            </div>
          </ListItem>
          <ListItem
            className="menuListItem"
            button
            disabled={!this.state.enableCancelTourPick || currentStep === 1}
          >
            <div id="menuSubList">
              <ListItemText primary={"Save Pick Tour"} onClick={this.handleToggle.bind(this)} />
            </div>
          </ListItem>
          <ListItem className="menuListItem" button>
            <div id="menuSubList">
              <ListItemText primary={"Log Out"} onClick={this.onLogout.bind(this)} />
            </div>
          </ListItem>
          <ListItem className="versionNumber">{version}</ListItem>
        </List>
      </div>
    );
    return (
      <div>
        <AppBar position="static" className="appBarS">
          <Toolbar className="p-0">
            <IconButton
              color="inherit"
              aria-label="Menu"
              onClick={this.toggleDrawer.bind(this, true)}
            >
              <MenuIcon />
            </IconButton>

            <div className={classes.refreshButtonContainer}>
              <OButton
                onButtonClick={this.forceReloadApp}
                data-testid="force-reload-app"
                text="Reload App"
                type="primary"
                size="sm"
                id="refreshAppButton"
              />
            </div>

            <div className={classes.grow}>{title}</div>
            <div id={`${id}.storeWrapper`} className="storeWrapper">
              <span id={`${id}.storeNumber`} className="storeNumber">
                {currentStep === 1 && (
                  <span className="editStoreId" onClick={this.showModalManualStore.bind(this)}>
                    Edit
                  </span>
                )}{" "}
                <span>Store #: {(store.storeId || "").replace(/^0+/, "") || "NOT SET"}</span>
              </span>
              <div id={`${id}.loggedSection`} className="loggedSection">
                {(data && data.claims && data.claims.cn) ||
                  (isFetchingTokenInfo ? (
                    <ContentLoader height={18} width={100} backgroundColor="#c9c9c9">
                      <Rect x="0" y="0" rx="5" ry="5" width="100" height="18" />
                    </ContentLoader>
                  ) : null)}
              </div>
            </div>
          </Toolbar>
        </AppBar>
        {this.props.showAppVersionUpdate && (
          <p className="appVersionUpdate">
            A newer version of the app is available.
            <button className="appVersionUpdateButton" onClick={this.forceReloadApp}>
              Reload
            </button>
          </p>
        )}
        <Drawer
          className="drawerWrapper"
          open={this.state.drawer}
          onClose={this.toggleDrawer.bind(this, false)}
        >
          <div
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer.bind(this, false)}
            onKeyDown={this.toggleDrawer.bind(this, false)}
          >
            {sideList}
          </div>
        </Drawer>
        {isSavePickTourActive && (
          <ADialog id="activeOrderDialog" modalProps={this.constructedProps()} />
        )}
      </div>
    );
  }
}

ApplicationBar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ApplicationBar);

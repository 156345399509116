/**
 * Copyright 2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 * @flow
 */

type PickTourListLogFields = {|
  commercialSalesOrderUID?: string,
  itemDS?: string,
  partNumberID?: string,
  itemID?: string
|};

export default function getPickTourLogFields(
  pickTour: {
    pickTourUID?: string,
    pickTourList:
      | void
      | {
          ...PickTourListLogFields,
          ...
        }[],
    ...
  } | void
): {
  pickTourUID: string | void,
  pickTourList: void | PickTourListLogFields[]
} {
  return {
    pickTourUID: pickTour?.pickTourUID,
    pickTourList: pickTour?.pickTourList?.map((order) => ({
      commercialSalesOrderUID: order.commercialSalesOrderUID,
      itemDS: order.itemDS,
      partNumberID: order.partNumberID,
      itemID: order.itemID
    }))
  };
}

import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import fetcher from "../utils/fetcher";
import { useAuth } from "../components/AuthContext";
import {
  updateManualStore,
  updateManualStoreWithSuccess,
  updateUserDetails
} from "../actions/loginActions";
import { clearSession } from "../constants/Authorization-util";
import {
  updateSelectedProducts,
  storePickTourList,
  clearSkippedItems,
  resetToPendingOrders,
  getOrders,
  upDateIsSavePicktourFlag,
  autoSavePickTour
} from "../actions/orderingActions";
import ChooseStoreModal from "../components/ChooseStoreModal";
import { version } from "../../package.json";
import HeaderComponent from "../components/HeaderComponent";
import eventLogger from "../utils/eventLogger";
import getDevice from "../utils/getDevice";
import OrderingContainer from "./OrderingContainer";

const UPDATE_CHECK_INTERVAL = 60 * 60 * 1000; // 1h

class AppContainer extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showAppVersionUpdate: false
    };
    this.device = getDevice();
    this.checkForAppUpdatesInterval = undefined;
  }

  onManualStoreSubmit(manualStoreNumber) {
    const uid = this.props.user?.userDetails?.claims.uid;
    this.props.updateManualStoreWithSuccess(false, manualStoreNumber);
    return this.props.getOrders(manualStoreNumber, uid);
  }
  onCloseManualStore() {
    this.props.updateManualStore(false);
  }

  upDateIsSavePicktourFlag(flag) {
    this.props.upDateIsSavePicktourFlag(flag);
  }

  render() {
    const { isManualStore, isSavePickTourActive } = this.props;
    return (
      <>
        <HeaderComponent
          id="appContainer.header"
          isSavePickTourActive={isSavePickTourActive}
          onLogout={this.signoutShop.bind(this)}
          savePickTour={this.savePickTour.bind(this)}
          upDateIsSavePicktourFlag={this.upDateIsSavePicktourFlag.bind(this)}
          showAppVersionUpdate={this.state.showAppVersionUpdate}
        />
        <main>
          {this.props.user && (
            <Router>
              <Switch>
                <Route exact path="/">
                  <OrderingContainer />
                </Route>
              </Switch>
            </Router>
          )}
          <ChooseStoreModal
            active={isManualStore}
            onClose={this.onCloseManualStore.bind(this)}
            onSubmit={this.onManualStoreSubmit.bind(this)}
          />
        </main>
      </>
    );
  }

  authorize = async () => {
    const { auth } = this.props;

    try {
      const tokens = await auth.authorize();
      if (tokens && tokens.accessToken) {
        eventLogger.infoWithMetadata(`${this.device} Login`, JSON.stringify({}));
        this.getUserTokenInfo();
      }
    } catch (error) {
      // Logging error in dev so it's harder to miss
      // eslint-disable-next-line no-console
      console.log("Failed to log in", error.message);
      clearSession("signout");
    }
  };

  componentDidMount() {
    // Periodically check for app updates. We do this, because this web app is
    // usually held open for long periods of time and, on mobile devices, it's
    // presented in a fullscreen mode, without ability to do a browser-refresh.
    this.checkForAppUpdatesInterval = setInterval(this.checkForAppUpdates, UPDATE_CHECK_INTERVAL);

    this.authorize();
  }

  componentWillUnmount() {
    clearInterval(this.checkForAppUpdatesInterval);
    clearInterval(this.checkIdleTimeInterval);
  }

  checkForAppUpdates = async () => {
    if (this.state.showAppVersionUpdate) {
      return;
    }

    const { data } = await fetcher.get("/version.json");

    if (version !== data.version) {
      this.setState({ showAppVersionUpdate: true });
    }
  };

  savePickTour(toClearSession, subStatus, isFromLogout = false) {
    let selectedPickTour = this.props.createPickTour;
    if (selectedPickTour && localStorage.getItem("isPickTourStarted") === "true") {
      selectedPickTour.assignedPickerEmployeeID = "";
      selectedPickTour.customerOrganizationLNM = "";
      selectedPickTour.location = "";
      selectedPickTour.pickTourSubStatusCD = subStatus;
      this.setState({ active: false });

      eventLogger.infoWithMetadata("Autosaving Picktour", JSON.stringify(selectedPickTour));
      this.props.autoSavePickTour(selectedPickTour).then(() => {
        if (toClearSession) {
          clearSession("signout");
        }
        this.clearSkippedTours();
        this.clearSelectedPickTourList();
        this.clearActivePicktour();
        if (isFromLogout) {
          this.props.resetToPendingOrders();
        }
      });
      return true;
    } else {
      if (toClearSession) {
        clearSession("signout");
        this.clearSkippedTours();
        this.clearSelectedPickTourList();
        this.clearActivePicktour();
        if (isFromLogout) {
          this.props.resetToPendingOrders();
        }
      }
    }
    return false;
  }
  // remove selected orders from redux store
  clearActivePicktour() {
    this.props.storePickTourList(null, 0, null);
  }
  // remove skipped orders from redux store
  clearSkippedTours() {
    this.props.clearSkippedItems();
  }
  // remove pending orders from redux store
  clearSelectedPickTourList() {
    this.props.updateSelectedProducts(null);
  }

  signoutShop() {
    this.savePickTour(true, "CANCEL", true);
  }

  getUserTokenInfo = async () => {
    await this.props.updateUserDetails();
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    createPickTour: state.inventory.createPickTour,
    user: state.user,
    store: state.store,
    isSavePickTourActive: state.inventory.isSavePickTourActive,
    isManualStore: state.user && state.user.isManualStore
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSelectedProducts: bindActionCreators(updateSelectedProducts, dispatch),
    storePickTourList: bindActionCreators(storePickTourList, dispatch),
    clearSkippedItems: bindActionCreators(clearSkippedItems, dispatch),
    resetToPendingOrders: bindActionCreators(resetToPendingOrders, dispatch),
    updateManualStore: bindActionCreators(updateManualStore, dispatch),
    updateManualStoreWithSuccess: bindActionCreators(updateManualStoreWithSuccess, dispatch),
    getOrders: bindActionCreators(getOrders, dispatch),
    upDateIsSavePicktourFlag: bindActionCreators(upDateIsSavePicktourFlag, dispatch),
    autoSavePickTour: bindActionCreators(autoSavePickTour, dispatch),
    updateUserDetails: bindActionCreators(updateUserDetails, dispatch)
  };
};

function AppContainerWithAuth(props) {
  const auth = useAuth();

  return <AppContainer auth={auth} {...props} />;
}

export default connect(mapStateToProps, mapDispatchToProps)(AppContainerWithAuth);

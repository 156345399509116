import React, { Component, Fragment } from "react";
import { trim, last, size, truncate, first } from "lodash";
import Divider from "@material-ui/core/Divider";
import MediaQuery from "react-responsive";
import Tooltip from "react-simple-tooltip";
// import MediaQuery from "react-responsive";
/* Core Library */
import ADialog from "../core-libs/components/Dialog";
/* Active Orders Component */
import OButton from "../core-libs/components/Button";
import TextInput from "../core-libs/components/TextInput";
import ErrorMessage from "../core-libs/components/ErrorMessage";

const closeIcon = require("../assets/images/close-x.svg");

export default class SkipItemValidation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSkipModal: false,
      selectedPartId: null,
      partQty: "",
      orderPickedQty: "",
      slectedPickOrder: null,
      isManuallyEntered: false,
      flag: false,
      skippedItem: "",
      equalQty: false,
      loading: false
    };
  }

  onSkipValueChange(evt) {
    evt.preventDefault();
    let _qty = parseInt(trim(evt.target.value));
    const { orderItemQY } = this.state.slectedPickOrder;
    const regEx = /^[+]?([0-9]+(?:\.[0-9]*)?|\.[0-9]+)$/;
    if (regEx.test(_qty) && _qty >= 0 && typeof _qty === "number") {
      if (this.state.skippedItem >= _qty) {
        let equalQty = this.state.skippedItem === _qty;
        _qty = _qty === 0 ? 0 : _qty;
        this.setState({
          partQty: _qty,
          orderPickedQty: orderItemQY - _qty,
          isManuallyEntered: true,
          flag: false,
          equalQty
        });
      } else {
        this.setState({ flag: true, isManuallyEntered: true });
      }
    } else {
      // reset to empty for skip quantity text box
      this.setState({ flag: true, isManuallyEntered: true, partQty: "" });
    }
  }

  onSubmit() {
    let { slectedPickOrder, partQty, selectedPartId } = this.state;
    let _slectedPickOrder = Object.assign({}, slectedPickOrder);
    let _skippedPickTours = Object.assign([], this.props.skippedPickTours);
    const isSat2Sat = _skippedPickTours.some((pickTour) =>
      pickTour.pickTourList.some((pick) => pick.pickListTypeCD === "SAT2SAT")
    );
    if (partQty >= 0) {
      _slectedPickOrder.skippedItemQY -= partQty;
      /* group all the skipped orders */
      let filterSkip = _skippedPickTours.filter((item) =>
        isSat2Sat
          ? item.pickTourList[0].replenishmentOrderHeaderUID === _slectedPickOrder.pId
          : item.pickTourList[0].partNumberID === selectedPartId
      );
      let _filteredSkipObj = Object.assign({}, filterSkip[0]);
      _filteredSkipObj.pickTourList.forEach((item, index) => {
        if (item.pickedItemQY + partQty >= item.orderItemQY) {
          partQty = partQty - (item.orderItemQY - item.pickedItemQY);
          const _orderItemQY = _filteredSkipObj.pickTourList[index].orderItemQY;
          _filteredSkipObj = Object.assign({}, _filteredSkipObj, {
            pickTourList: Object.assign([], _filteredSkipObj.pickTourList, {
              [index]: Object.assign({}, _filteredSkipObj.pickTourList[index], {
                isSkippedItemFL: "N",
                pickedItemQY: _orderItemQY
              })
            })
          });
        } else {
          const _pickQty = _filteredSkipObj.pickTourList[index].pickedItemQY;
          _filteredSkipObj = Object.assign({}, _filteredSkipObj, {
            pickTourList: Object.assign([], _filteredSkipObj.pickTourList, {
              [index]: Object.assign({}, _filteredSkipObj.pickTourList[index], {
                pickedItemQY: _pickQty + partQty
              })
            })
          });
          partQty = 0;
        }
      });
      let { skippedPickTours, getPickTourListForSkipValidation } = this.props;
      const skippedPick = getPickTourListForSkipValidation(skippedPickTours)._finalList;
      const _lastPick = last(skippedPick);
      // check is the last item in the selected orders
      let isLastRecord = isSat2Sat
        ? _lastPick.pId === _slectedPickOrder.pId
        : _lastPick.partNumberID === _slectedPickOrder.partNumberID;
      this.props
        .updateSkipPickTour(
          _filteredSkipObj,
          _skippedPickTours,
          _slectedPickOrder,
          this.props.user,
          isLastRecord,
          this.props.skipCompletedList,
          this.props.createPickTour
        )
        .then((response) => {
          this.props.clearServiceErrorNotifications();
          this.toggleModal(false, null);
        });
    }
  }
  // call /update api for skip orders
  onFoundAll() {
    let { slectedPickOrder, selectedPartId } = this.state;
    const isSat2Sat = this.props.skippedPickTours.some((pickTour) =>
      pickTour.pickTourList.some((pick) => pick.pickListTypeCD === "SAT2SAT")
    );
    let filterSkip = this.props.skippedPickTours.filter((item) =>
      isSat2Sat
        ? item.pickTourList[0].replenishmentOrderHeaderUID === slectedPickOrder.pId
        : item.pickTourList[0].partNumberID === selectedPartId
    );
    let _filteredSkipObj = Object.assign({}, filterSkip[0]);
    _filteredSkipObj.pickTourList.forEach((item, index) => {
      const _orderItemQY = _filteredSkipObj.pickTourList[index].orderItemQY;
      _filteredSkipObj = Object.assign({}, _filteredSkipObj, {
        pickTourList: Object.assign([], _filteredSkipObj.pickTourList, {
          [index]: Object.assign({}, _filteredSkipObj.pickTourList[index], {
            pickedItemQY: _orderItemQY,
            isSkippedItemFL: "N"
          })
        })
      });
    });
    let { skippedPickTours, getPickTourListForSkipValidation } = this.props;
    const skippedPick = getPickTourListForSkipValidation(skippedPickTours)._finalList;
    const _lastPick = last(skippedPick);
    let isLastRecord = isSat2Sat
      ? _lastPick.pId === slectedPickOrder.pId
      : _lastPick.partNumberID === slectedPickOrder.partNumberID;
    this.props
      .updateSkipPickTour(
        _filteredSkipObj,
        this.props.skippedPickTours,
        slectedPickOrder,
        this.props.user,
        isLastRecord,
        this.props.skipCompletedList,
        this.props.createPickTour
      )
      .then((response) => {
        this.props.clearServiceErrorNotifications();
        this.toggleModal(false, null);
      });
  }
  // trigger when the enter key pressed on quantity to call api
  enterPressed(e) {
    let value = e.keyCode || e.which;
    if (value === 13) {
      let _qty = this.state.partQty;
      if (_qty === this.state.skippedItem) {
        this.onFoundAll();
      } else if (_qty < this.state.skippedItem && _qty > 0) {
        this.onSubmit();
      }
    }
  }
  async onConfirmSkip(skippedItem, isLastRecord) {
    const isSat2Sat = this.props.skippedPickTours.some((pickTour) =>
      pickTour.pickTourList.some((pick) => pick.pickListTypeCD === "SAT2SAT")
    );
    let filterSkip = this.props.skippedPickTours.filter((item) =>
      isSat2Sat
        ? item.pickTourList[0].replenishmentOrderHeaderUID === skippedItem.pId
        : item.pickTourList[0].partNumberID === skippedItem.partNumberID
    );
    try {
      this.setState({ loading: true });
      await this.props.updateSkipPickTour(
        filterSkip[0],
        this.props.skippedPickTours,
        skippedItem,
        this.props.user,
        isLastRecord,
        this.props.skipCompletedList,
        this.props.createPickTour
      );
      this.props.clearServiceErrorNotifications();
    } catch (error) {
      this.props.notifyConfirmSkipServiceError(error);
    } finally {
      this.setState({ loading: false });
    }
  }
  constructedProps() {
    const { selectedPartId, partQty, slectedPickOrder } = this.state;
    const _partQty = partQty.toString();
    const errors = this.props.errors;
    const _partQtyLengthClass = _partQty.length <= 1 ? "skipItemOrderQty" : "skipItemOrderQtyBig";
    return {
      active: this.state.showSkipModal,
      title: "",
      bodyText: (
        <div id="itemFound">
          <div className="skipTitle">
            <div className="skipText">Item Found</div>
            <div className="closeBtn">
              <span className="closeImg" onClick={this.toggleModal.bind(this, false, null)}>
                <img alt="closeIcon" src={closeIcon} />
              </span>
            </div>
          </div>
          <div className="skipBar">
            <span className="requestText">
              <span className="skipValLabel">Qty. Requested:</span>
              <span className="skipValLabelValue">
                {slectedPickOrder && slectedPickOrder.orderItemQY}
              </span>
            </span>
            <span className="pickText">
              <span className="skipValLabel">Qty. Picked:</span>
              <span className="skipValLabelValue">
                {(slectedPickOrder && slectedPickOrder.orderItemQY) -
                  (slectedPickOrder && slectedPickOrder.skippedItemQY)}
              </span>
            </span>
            <span className="skippedText">
              <span className="skipValLabel">Qty. Skipped:</span>
              <span className="skipValLabelValue">
                {slectedPickOrder && slectedPickOrder.skippedItemQY}
              </span>
            </span>
          </div>
          <div className="skipBody">
            <fieldset className="fldset">
              <div>
                <TextInput
                  id="activeSkipPartNumber"
                  size="sm"
                  type="text"
                  placeholder=" "
                  value={selectedPartId || ""}
                  readOnly
                  handleChange={() => {}}
                />
                <legend htmlFor="activeSkipPartNumber"> Part Number</legend>
              </div>
            </fieldset>
            <fieldset className="fldset wid115">
              <div>
                <input
                  id="skipQtyTextBox"
                  type="text"
                  placeholder=" "
                  autoComplete="off"
                  className={_partQtyLengthClass}
                  value={partQty}
                  onChange={(event) => this.onSkipValueChange(event)}
                  onKeyPress={this.enterPressed.bind(this)}
                />
                <legend htmlFor="skipQtyTextBox"> Qty. Found</legend>
              </div>
            </fieldset>
          </div>
          <div>
            {errors?.partSubmitError ? (
              <div className="itemErrorWrapper itemFoundErrorWrapper">
                <ErrorMessage type="submitting" error={errors.partSubmitError} />
              </div>
            ) : null}
          </div>
          <Divider />
          <div className="skipAction">
            <OButton
              id="skipAll"
              text="Submit"
              customClass="submitButtonModal"
              disabled={(!this.state.isManuallyEntered || this.state.flag) && !this.state.equalQty}
              onButtonClick={this.onSubmit.bind(this)}
              data-testid="skip-item-validation-submit"
            />
            <OButton
              id="skipAll"
              customClass="secondaryButton"
              text="Found All"
              onButtonClick={this.onFoundAll.bind(this)}
              disabled={this.state.isManuallyEntered && !this.state.equalQty}
              data-testid="skip-item-validation-found-all"
            />
          </div>
        </div>
      )
    };
  }
  /* Toggle the modal based on the value passed */
  toggleModal(val, _firstPick = null) {
    if (_firstPick) {
      this.setState({
        showSkipModal: val,
        selectedPartId: _firstPick.partNumberID,
        slectedPickOrder: _firstPick,
        partQty: _firstPick.skippedItemQY,
        skippedItem: _firstPick.skippedItemQY
      });
    } else {
      this.setState({ showSkipModal: val, selectedPartId: null, isManuallyEntered: false });
    }
    this.props.clearServiceErrorNotifications();
  }
  /* construct POG DOM */
  getPOGSEQByPartNumber(createPickTour, partNumberID, pId) {
    const _pickTourList = createPickTour && createPickTour.pickTourList;

    const isSat2Sat =
      _pickTourList &&
      _pickTourList.some((pickTourItem) => pickTourItem.pickListTypeCD === "SAT2SAT");

    const _selectePickTour =
      _pickTourList &&
      size(_pickTourList) > 0 &&
      _pickTourList.find((item) =>
        isSat2Sat ? item.replenishmentOrderHeaderUID === pId : item.partNumberID === partNumberID
      );
    const _pogSeqDom =
      _selectePickTour && _selectePickTour.location && size(_selectePickTour.location) > 0 ? (
        _selectePickTour.location.map((item) => {
          return (
            <Fragment key={item.planoID}>
              <div className="planogramWrapper">
                <div className="planoGramLabel">
                  {item.planoDescription !== "unavailable" && (
                    <Tooltip
                      padding={5}
                      placement="top"
                      content={item.planoDescription}
                      customCss={`& > div {width: 100% !important; & { div:nth-child(1) { word-break: break-all; font-size: 12px; } } }`}
                    >
                      <span className="pogLabel">
                        {truncate(item.planoDescription, {
                          length: 20,
                          omission: "..."
                        })}
                      </span>
                    </Tooltip>
                  )}
                  {item.planoDescription === "unavailable" && (
                    <Tooltip
                      padding={5}
                      placement="top"
                      content={item.planoID}
                      customCss={`& > div {width: 100% !important; & { div:nth-child(1) { word-break: break-all; font-size: 12px; } } }`}
                    >
                      <span className="pogLabel">
                        {truncate(item.planoID, {
                          length: 20,
                          omission: "..."
                        })}
                      </span>
                    </Tooltip>
                  )}
                </div>
                <div className="seqLabel">
                  <div className="din">{item.itemSequenceNumber}</div>
                </div>
              </div>
            </Fragment>
          );
        })
      ) : (
        <div className="planogramWrapper">
          <div className="planoGramLabel">
            <span className="pcgLabel">
              {isSat2Sat ? "Location Data Not Available" : "Hub/VDP/Sister Store"}
            </span>
          </div>
        </div>
      );
    return _pogSeqDom;
  }

  render() {
    let { skippedPickTours, getPickTourListForSkipValidation, errors } = this.props;
    const skippedPick = getPickTourListForSkipValidation(skippedPickTours)._finalList;
    const isSat2Sat = skippedPick.some((pick) => pick.pickListTypeCD === "SAT2SAT");
    const totalCount = size(skippedPick) + size(this.props.skipCompletedList);
    return (
      <div id="skipValidation">
        <Fragment>
          {skippedPick &&
            skippedPick.map((item, index) => {
              const _firstPick = first(skippedPick);
              const _lastPick = last(skippedPick);
              return (
                <div key={"itemsActiveOrInactive." + index} className="activeOrdersWrapper clrBoth">
                  {(isSat2Sat
                    ? _firstPick.pId === item.pId
                    : _firstPick.partNumberID === item.partNumberID) &&
                    index === 0 && (
                      <div key={"activeInactiveOrders." + index}>
                        <div className="selectedHeadingSection">
                          <div className="alignedItems">
                            <div className="labelPartIdWrapper">
                              <span className="labelPartId">Part #:</span>
                              <span className="labelPartIdValue">{item.partNumberID}</span>
                              <span className="noOfItems">
                                <span>{`${
                                  size(this.props.skipCompletedList) + 1
                                } of ${totalCount} Items`}</span>
                              </span>
                            </div>
                            <div className="quantityPickWrapper">
                              <div className="qtyRequested">
                                Qty. Requested:{" "}
                                <span className="labelIdValue">{item.orderItemQY}</span>
                              </div>
                              <div className="qtyPicked">
                                Qty. Picked:{" "}
                                <span className="labelIdValue">
                                  {item.orderItemQY - item.skippedItemQY}
                                </span>
                              </div>
                              <div className="qtySkipped">
                                Qty. Skipped:{" "}
                                <span className="labelIdValue">{item.skippedItemQY}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <MediaQuery minWidth={481}>
                          {errors?.skipItemError ? (
                            <div className="itemErrorWrapper">
                              <ErrorMessage type="submitting" error={errors.skipItemError} />
                            </div>
                          ) : null}
                        </MediaQuery>
                        <div className="actionAlignedItems">
                          <MediaQuery minWidth={481}>
                            <div className="selectedControlSection skipitm">
                              <div className="actionItems">
                                <div className="itemDesc wid401">{item.itemDS}</div>
                                <div className="completeButtonWrapper">
                                  <OButton
                                    onButtonClick={this.onConfirmSkip.bind(
                                      this,
                                      _firstPick,
                                      isSat2Sat
                                        ? _lastPick.pId === item.pId
                                        : _lastPick.partNumberID === item.partNumberID
                                    )}
                                    data-testid="skip-item-validation-confirm"
                                    type="primary"
                                    id="btnCompleteItem"
                                    text="Confirm Skip"
                                    size="sm"
                                    customClass="confirmSkip"
                                    loading={this.state.loading}
                                  />
                                  <br />
                                  <div className="btn-alignm mtp16">
                                    <OButton
                                      onButtonClick={this.toggleModal.bind(this, true, _firstPick)}
                                      data-testid="skip-item-validation-toggle-modal"
                                      type="primary"
                                      id="btnCompleteItem"
                                      text="Item Found"
                                      size="sm"
                                      customClass="itemFoundButton"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="planogramWrapper planoWrapperStyle w-100" />
                              <div className="controlSectionFooterItemFound">
                                <div className="ctrlSectionItem">
                                  <span className="planoGramLabel wid314">
                                    <span className="pogLabel">
                                      <strong>POG</strong>
                                    </span>
                                  </span>
                                  <span className="seqLabel sml">
                                    <strong className="din">SEQ</strong>
                                  </span>
                                  {this.getPOGSEQByPartNumber(
                                    this.props.createPickTour,
                                    item.partNumberID,
                                    item.pId
                                  )}
                                </div>
                                <div className="itemFound">
                                  <ADialog
                                    id="activeOrderDialog"
                                    modalProps={this.constructedProps()}
                                    handleToggle={this.toggleModal.bind(this, false, _firstPick)}
                                  />
                                </div>
                              </div>
                            </div>
                          </MediaQuery>
                          <MediaQuery maxWidth={480}>
                            <div className="selectedControlSection skipitm">
                              <div className="actionItems">
                                <div className="itemDesc">{item.itemDS}</div>
                                <div className="planogramWrapper planoWrapperStyle">
                                  <span className="planoGramLabel wid314 pt-24">
                                    <span className="pogLabel">
                                      <strong>POG</strong>
                                    </span>
                                  </span>
                                  <span className="seqLabel">
                                    <strong>SEQ</strong>
                                  </span>
                                </div>
                                <div className="ctrlSectionItem">
                                  {this.getPOGSEQByPartNumber(
                                    this.props.createPickTour,
                                    item.partNumberID,
                                    item.pId
                                  )}
                                </div>
                                <div className="controlSectionFooterItemFound">
                                  <div className="errMsg">
                                    {errors?.skipItemError ? (
                                      <div className="itemErrorWrapper">
                                        <ErrorMessage
                                          type="submitting"
                                          error={errors.skipItemError}
                                        />
                                      </div>
                                    ) : null}
                                  </div>
                                  <div className="completeButtonWrapper">
                                    <OButton
                                      onButtonClick={this.onConfirmSkip.bind(
                                        this,
                                        _firstPick,
                                        isSat2Sat
                                          ? _lastPick.pId === item.pId
                                          : _lastPick.partNumberID === item.partNumberID
                                      )}
                                      data-testid="skip-item-validation-confirm-skip"
                                      type="primary"
                                      id="btnCompleteItem"
                                      text="Confirm Skip"
                                      size="sm"
                                      customClass="confirmSkip"
                                      loading={this.props.loading}
                                    />
                                  </div>
                                </div>
                                <div className="itemFound">
                                  <OButton
                                    onButtonClick={this.toggleModal.bind(this, true, _firstPick)}
                                    data-testid="skip-item-validation-toggle-modal"
                                    type="primary"
                                    id="btnCompleteItem"
                                    text="Item Found"
                                    size="sm"
                                    customClass="itemFoundButton"
                                  />
                                  <ADialog
                                    id="activeOrderDialog"
                                    modalProps={this.constructedProps()}
                                    handleToggle={this.toggleModal.bind(this, false, _firstPick)}
                                  />
                                </div>
                              </div>
                            </div>
                          </MediaQuery>
                        </div>
                      </div>
                    )}
                  {(isSat2Sat
                    ? _firstPick.pId !== item.pId
                    : _firstPick.partNumberID !== item.partNumberID) && (
                    <div
                      key={"inactiveInactiveOrders." + index}
                      className="unSelectedHeadingSection"
                    >
                      <div className="alignedItems">
                        <div>
                          <span className="labelPartId">Part #:</span>
                          <span className="labelPartIdValue">{item.partNumberID}</span>
                          <span className="noOfItems">
                            <span>{`${index + 1} of ${totalCount} Items`}</span>
                          </span>
                        </div>
                        <div className="quantityPickWrapper">
                          <div className="qtyRequested">
                            Qty. Requested: <span className="labelIdValue">{item.orderItemQY}</span>
                          </div>
                          <div className="qtyPicked">
                            Qty. Picked:{" "}
                            <span className="labelIdValue">
                              {item.orderItemQY - item.skippedItemQY}
                            </span>
                          </div>
                          <div className="qtySkipped">
                            Qty. Skipped: <span className="labelIdValue">{item.skippedItemQY}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
        </Fragment>
      </div>
    );
  }
}

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ErrorBoundary } from "react-error-boundary";
import AppBar from "../core-libs/components/AppBar";
import { updateSelectedProducts, storePickTourList, getOrders } from "../actions/orderingActions";
import { postDriverLogoff, updateManualStore } from "../actions/loginActions";

import ErrorFallback from "../containers/ErrorFallback";
import getDevice from "../utils/getDevice";
export class HeaderComponent extends Component {
  async onLogout() {
    const device = getDevice();
    if (device !== "computer") {
      const employeeID = this.props.user?.claims.uid;
      await postDriverLogoff(employeeID);
    }
    if (this.props.onLogout) {
      this.props.onLogout();
    }
  }

  getOrders(storeId) {
    const uid = this.props.user?.userDetails?.claims.uid;
    setTimeout(() => {
      this.props.getOrders(storeId, uid);
    }, 500);
  }

  clearSelectedPickTourList() {
    this.props.updateSelectedProducts(null);
  }
  clearActivePicktour() {
    this.props.storePickTourList(null, 0);
  }
  upDateIsSavePicktourFlag(flag) {
    this.props.upDateIsSavePicktourFlag(flag);
  }

  render() {
    let {
      id,
      user,
      store,
      isFetchingTokenInfo,
      savePickTour,
      currentStep,
      isSavePickTourActive,
      showAppVersionUpdate
    } = this.props;

    return (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <AppBar
          id={`${id}.header`}
          isSavePickTourActive={isSavePickTourActive}
          currentStep={currentStep}
          data={user}
          store={store}
          isFetchingTokenInfo={isFetchingTokenInfo}
          onLogout={this.onLogout.bind(this)}
          savePickTour={savePickTour}
          getOrders={this.getOrders.bind(this)}
          showModalManualStore={() => this.props.updateManualStore(true)}
          upDateIsSavePicktourFlag={this.upDateIsSavePicktourFlag.bind(this)}
          showAppVersionUpdate={showAppVersionUpdate}
        />
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user && state.user.userDetails ? state.user.userDetails : null,
    store: state.store,
    currentStep: state.inventory && state.inventory.currentStep,
    isFetchingTokenInfo: state.user && state.user.isFetchingTokenInfo
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSelectedProducts: bindActionCreators(updateSelectedProducts, dispatch),
    storePickTourList: bindActionCreators(storePickTourList, dispatch),
    getOrders: bindActionCreators(getOrders, dispatch),
    updateManualStore: bindActionCreators(updateManualStore, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderComponent);

import * as types from "../constants/actionTypes";

const initialState = {
  startPickTourError: false,
  getOrdersRefreshError: false,
  completeItemError: false,
  partSubmitError: false,
  skipItemError: false,
  printError: false,
  forwardError: false,
  continueError: false,
  getOrdersError: false
};

export const errorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.E_GET_ORDERS: {
      return { ...state, getOrdersError: action.flag };
    }
    case types.E_POST_PICK_TOUR: {
      return { ...state, startPickTourError: action.flag };
    }
    case types.E_REFRESH_GET_ORDERS: {
      return { ...state, getOrdersRefreshError: action.flag };
    }
    case types.E_COMPLETE_ITEM: {
      return { ...state, completeItemError: action.flag };
    }
    case types.E_PART_SUBMIT_AND_COMPLETE: {
      return { ...state, partSubmitError: action.flag };
    }
    case types.E_SKIP_ITEM: {
      return { ...state, skipItemError: action.flag };
    }
    case types.E_PRINT: {
      return { ...state, printError: action.flag };
    }
    case types.E_FORWARD: {
      return { ...state, forwardError: action.flag };
    }
    case types.E_CONTINUE: {
      return { ...state, continueError: action.flag };
    }
    case types.CLEAR_SERVICE_ERROR_MESSAGES:
    case types.CLEAR_ALL_ERROR_MESSAGES: {
      return initialState;
    }
    default:
      return state;
  }
};
